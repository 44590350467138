import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "tab"];
  connect() {
    this.tabTargets.forEach((tab, index) => {
      tab.addEventListener("click",() => this.submitTabForm(index));
    });
    // automatically submit the first (default) tab form
    this.formTargets[0].requestSubmit();
  }

  submit() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this._submit();
    }, 300);
  }

  submitNow() {
    this._submit();
  }

  submitTabForm(index) {
    if(this.formTargets[index]) {
      this.formTargets[index].requestSubmit();
    }
  }

  submitWithIndex(event) {
    this.formTargets[event.target.dataset.index].requestSubmit();
  }

  _submit() {
    this.formTarget.requestSubmit();
  }
}